import axios from '../axios-instance';
import { API_URL } from '../api-url/api-url.service';
export default class DataSetsApi {
    static async globalDescribe(organizationId) {
        const { data } = await axios.get(`${API_URL}/data-sets/global-describe`, {
            params: {
                organizationId,
            },
        });
        return data;
    }
    static async countRecords(body) {
        const { data } = await axios.post(`${API_URL}/data-sets/sf-count`, body);
        return data;
    }
    static async validateSoqlFilter(body) {
        await axios.post(`${API_URL}/data-sets/validate-soql-filter`, body);
    }
    static async objectDescribe(organizationId, objectName) {
        const { data } = await axios.get(`${API_URL}/data-sets/object-describe`, {
            params: {
                organizationId,
                objectName,
            },
        });
        return data;
    }
    static async create(body) {
        await axios.post(`${API_URL}/data-sets`, body);
    }
    static async update(id, body) {
        await axios.put(`${API_URL}/data-sets/${id}`, body);
    }
    static async findById(id) {
        const { data } = await axios.get(`${API_URL}/data-sets/${id}`);
        return data;
    }
    static async remove(id) {
        await axios.delete(`${API_URL}/data-sets/${id}`);
    }
    static async find({ organizationId, search, page, limit }) {
        const { data } = await axios.get(`${API_URL}/data-sets`, {
            params: {
                organizationId,
                search,
                page,
                limit,
            },
        });
        return data;
    }
}
