import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import DataSetsList from '@/components/data-sets/DataSetsList.vue';
let DataSetsIndexPage = class DataSetsIndexPage extends Vue {
    createDataSet() {
        this.$router.push({
            name: 'data-sets.manage',
            params: {
                dataSetId: 'new',
            },
        });
    }
};
DataSetsIndexPage = __decorate([
    Component({
        components: {
            DataSetsList,
        },
    })
], DataSetsIndexPage);
export default DataSetsIndexPage;
