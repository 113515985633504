import { __decorate } from "tslib";
import { Vue, Component, Inject, Watch } from 'vue-property-decorator';
import { DEFAULT_PAGINATION_LIMIT } from '@/components/FPagination.vue';
import DataSetsApi from '@/services/api/data-sets.api';
let DataSetsList = class DataSetsList extends Vue {
    constructor() {
        super(...arguments);
        this.tableFields = [
            { key: 'index', label: '#', thStyle: { width: '4%' } },
            { key: 'name', label: 'Name', thStyle: { width: '10%' } },
            { key: 'objectApiName', label: 'Object', thStyle: { width: '10%' } },
            { key: 'fields', label: 'Fields', thStyle: { width: '20%' } },
            { key: 'queryWhereClause', label: 'Where Clause', thStyle: { width: '20%' } },
            { key: 'description', label: 'Description', thStyle: { width: '30%' } },
            { key: 'actions', label: '', thStyle: { width: '6%' } },
        ];
        this.tableRef = null;
        this.expandedRow = -1;
        this.loading = false;
        this.search = '';
        this.pagination = {
            limit: DEFAULT_PAGINATION_LIMIT,
            page: 1,
            total: 1000,
        };
        this.items = [];
    }
    get localOffset() {
        return (this.pagination.page - 1) * this.pagination.limit;
    }
    onPageChanged() {
        this.$nextTick(() => this.fetchDataSets());
    }
    toggleTableRow(index) {
        if (this.tableRef) {
            const tableBody = this.tableRef.$children[1];
            const tableRow = tableBody.$children[index];
            tableRow?.$el.classList.toggle('data-sets-list__row--expanded');
        }
    }
    expandRow(index) {
        if (this.expandedRow !== -1 && this.expandedRow !== index) {
            this.toggleTableRow(this.expandedRow);
        }
        this.expandedRow = this.expandedRow === index ? -1 : index;
        this.toggleTableRow(index);
    }
    async openDataSet(item) {
        await this.$router.push({
            name: 'data-sets.manage',
            params: {
                dataSetId: item.id.toString(),
            },
        });
    }
    async remove(item) {
        try {
            if (await this.$confirm()) {
                await DataSetsApi.remove(item.id);
                this.$toast.success('Data Set successfully deleted');
                await this.fetchDataSets();
            }
        }
        catch (error) {
            this.$toast.error(error.message);
        }
    }
    mounted() {
        this.fetchDataSets();
    }
    async fetchDataSets() {
        try {
            this.loading = true;
            this.items = [];
            const { dataSets, total } = await DataSetsApi.find({
                organizationId: +this.organizationId,
                search: this.search,
                limit: this.pagination.limit,
                page: this.pagination.page,
            });
            this.pagination.total = total;
            this.items = dataSets.map(dataset => ({
                ...dataset,
                queryWhereClause: dataset.queryWhereClause?.replace(/\{0}\./g, ''),
            }));
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Inject()
], DataSetsList.prototype, "organizationId", void 0);
__decorate([
    Watch('pagination.page', { deep: true }),
    Watch('search')
], DataSetsList.prototype, "onPageChanged", null);
DataSetsList = __decorate([
    Component({})
], DataSetsList);
export default DataSetsList;
